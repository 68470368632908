html {
  user-select: none;   
}

body {
  user-select: none; 
}

.container {}

.window-body {
  color: black !important;
}

.mb8 {
  margin-bottom: 8px;
}

.underline {
  text-decoration: underline;
}

.start-bar {
  width: 246px;
  height: 33px;
  float: left;
  position: relative;
  background: linear-gradient(71deg, navy, #1084d0);
  margin-right: 5px;
  transform: rotate(-90deg);
  left: 11px;
  transform-origin: left;
  top: 260px;
  color: white;
  font-weight: bold;
}

.window {
  margin: 8px;
}


.start-window > .window-body {
  margin: 0px !important;
}

.start-item {
  text-decoration: none;
  color:black;
}

.start-item > div {
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 3px;
}

.start-item:hover>div {
  color: white;
  background-color: navy;
}

.start-img {
  width: 34px;
  float: left;
}

.start-window > div > div > hr{
  margin: 0px;
}

.start-text {
  width: calc(100% - 45px);
  float: right;
  padding-top: 8px

}

.form-validation{
  color: #cc0000;
}


.borderedTable{
  border: 1px solid white;
}

.borderedTable td {
  border: 1px solid white;
  padding: 8px;
}

.borderedTable th {
  border: 1px solid white;
  padding: 8px;
}

input[type=text] {
  
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.container{
  padding: 24px;
}

.footer {
  bottom: 0px;
  position: fixed;
  height: 28px;
  width: 100%;
}

.desktop {
  background-image: url('./assets/img/wallpaper.jpg');
  background-position: center;
  bottom: 0px;
    position: fixed;
    height: 100%;
    width: 100%;
}

.taskbar-clock {
  width: 89px;  
  float: right;
  margin-right: 6px;
  padding: 2px !important;
  height: 20px;
}

.desktopIconContainer1 {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
  flex-flow: wrap;
  flex-direction: column;
  width: 118px;
}

.selected-icon > span {
  background: rgb(0, 0, 128);
  color: white;
  padding: 4px;
  padding: 1px 5px 1px 4px;
}